import React, {useState, useEffect} from 'react'
import {colors} from '@atlaskit/theme'
import {grid, borderRadius} from './constants'
import {Draggable} from 'react-beautiful-dnd'
import {deleteBoardColumn, updateColumnById} from '../../services/column'
import ReactSkeleton from 'react-loading-skeleton'
import CardList from './Card/CardList'
import styled from '@emotion/styled'
import Title from './Card/title'
import AddNewCard from '../AddCard/AddNewCardBoard'
import UpdateColumnColorModal from '../Modals/UpdateColumnColorModal'
import DeleteColumnModal from '../Modals/DeleteColumnModal'
import EditableTitle from './EditableTitle'
import AddCardFromDownBtn from './Card/AddCardFromDownBtn'
import ColumnHeaderButtons from './ColumnHeaderButtons'
import '../../styles/inputStyle.css'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import './Column.css'
import {webSocketOperations} from '../../constants'
import {capitalizeEachWord, getSkeletonHeight} from '../../helper-functions/CapitalizeName'
import { useTheme } from '../../context/ThemeContext'
import ColumnRulesModal from '../Modals/ColumnRulesModal'
import { useBoardPersons } from '../../hooks'
const Container = styled.div`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  // background-color: ${({isDragging}: any) => (isDragging ? colors.G50 : colors.N30)};
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${colors.G50};
  }
`
const cardListStyle = {
  backgroundColor: 'rgb(235, 235, 235)',
  width: '310px',
  height: '54vh ',
  overflowY: 'auto',
  padding: '0',
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Column = ({
  boardCardFetchLoading,
  sendJsonMessage,
  fetchCardHandler,
  fetchSortedCardHandler,
  // columnName,
  checked,
  role,
  columnResponse,
  setColumnsResponse,
  columnId,
  title,
  quotes,
  index,
  isScrollable,
  isCombineEnabled,
  bgColor,
  useClone,
  cards,allCards,
  handleCloneCard,
  setCards,
  columnsBg,
  setColumnsBg,
  optionsOfAssignee,
}: any): React.JSX.Element => {
  const [colorLoading, setColorLoading] = useState(false)
  const [addCardModalShow, setAddModalShow] = useState(false)
  const [deleteModalMessage, setDeleteModalMessage] = useState('')
  const [isEditable, setIsEditable] = useState(false)
  const [columnTitle, setColumnTitle] = useState(title?.trim() || '')
  const [newTitle, setNewTitle] = useState('')
  const [columnIdState, setColumnIdState] = useState('')
  const [cardPositionStatus, setCardPositionStatus] = useState('')
  const [backgroundColorState, setBackgroundColorState] = useState('#0000ff')
  const [selectedColor, setSelectedColor] = useState('')
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [columnRuleModalShow, setColumnRuleModalShow] = useState(false)
  const [showColor, setShowColor] = useState(false)
  const isPageArchiveBoard = window.location.href.includes('archived')
  const [skeletonHeight] = useState(getSkeletonHeight())
  const params: any = useParams()
  const {currentUser} = useAuth()
  const {theme} = useTheme()
  const {id: userId, fullName, role: checkRole} = currentUser?.data.user
  //guest -mode : hide add card controllers

  const handleRuleModalClose = () => {
    setColumnRuleModalShow(false)
  }
  const handleRuleModalShow = () => {
    setColumnRuleModalShow(true)
  }
  const handleClose = () => {
    setDeleteModalShow(false)
    setShowColor(false)
  }
  const handleShow = () => {
    setDeleteModalMessage('')
    setDeleteModalShow(true)
  }
  const {
    setSelectAutoAssignee,
    selectAutoAssignee,
  } = useBoardPersons()
  const deleteColumnHandler = async () => {
    try {
      const isColumnExist = columnResponse?.find((col: any) => col.id === columnId)
      if (isColumnExist !== undefined) {
        const isCardExistInColumn = cards?.filter(
          (card: any) => card.board_column_id === isColumnExist.id
        ).length
        if (isCardExistInColumn > 0) {
          setDeleteModalMessage(
            `You must remove all task cards from "${columnTitle}" before you can delete it.`
          )
          return
        }
      }

      const filterColumn = columnResponse.filter((col: any) => col.id !== columnId)
      setColumnsResponse(filterColumn)

      setCards(cards.filter((card: any) => card.board_column_id !== columnId))
      await deleteBoardColumn(columnId, userId, params.boardId)
      if (checked && filterColumn.length === 0) {
        fetchSortedCardHandler(params.boardId)
      } else if (filterColumn.length === 0) {
        await fetchCardHandler(params.boardId)
      }

      const webSocketPayload: any = {
        event: 'publish',
        channel: params.boardId,
        userId,
        command: 'DELETE',
        operation: webSocketOperations.columnDelete,
        payload: {
          columnId,
          boardId: params.boardId,
          alert: `${capitalizeEachWord(fullName)} deleted column ${columnTitle}.`,
        },
      }
      sendJsonMessage(webSocketPayload)
    } catch (error) {
      return error
    }
  }

  const updateColumnColor = async () => {
    setColorLoading(true)
    setColumnsBg((prevColumnsBg: any) => {
      return [...prevColumnsBg, backgroundColorState]
      setColorLoading(false)
    })

    try {
      await updateColumnById(
        {color: backgroundColorState, boardId: params.boardId},
        columnIdState,
        userId
      )
      setSelectedColor(backgroundColorState)
      const webSocketPayload: any = {
        event: 'publish',
        channel: params.boardId,
        userId,
        command: 'UPDATE',
        operation: webSocketOperations.columnColorUpdate,
        payload: {
          newColor: backgroundColorState,
          columnId: columnIdState,
          boardId: params.boardId,
          alert: `${capitalizeEachWord(fullName)}  did changes in column color.`,
        },
      }
      const updatedColumns = columnResponse.map((col: any) =>
        col.id === columnIdState ? {...col, color: backgroundColorState} : col
      )
      setColumnsResponse(updatedColumns)
      sendJsonMessage(webSocketPayload)
    } catch (error) {
      return error
    } finally {
      handleClose()
      setColorLoading(false)
    }
  }

  const updateColumnTitleHandler = async () => {
    setIsEditable(false)
    if (columnTitle?.length > 0 && /\S/.test(columnTitle)) {
      try {
        await updateColumnById({name: columnTitle, boardId: params.boardId}, columnIdState, userId)
        setNewTitle(columnTitle)
        const webSocketPayload: any = {
          event: 'publish',
          channel: params.boardId,
          userId,
          command: 'UPDATE',
          operation: webSocketOperations.boardColumnNameUpdate,
          payload: {
            newName: columnTitle,
            columnId: columnIdState,
            boardId: params.boardId,
            alert: `${capitalizeEachWord(fullName)} did changes in column title.`,
          },
        }
        sendJsonMessage(webSocketPayload)
      } catch (error) {
        return error
      }
    }
  }

  useEffect(() => {
    return () => setDeleteModalMessage('')
  }, [])
  return (
    <>
      {/* If role is admin give them access of column update */}
      {/* && (checkRole?.isGuest !== true && role !== 'Guest') */}
      {role === 'Admin' ? (
        <div>
          <Draggable draggableId={columnId} index={index}>
            {(provided: any, snapshot: any) => (
              <Container ref={provided.innerRef} {...provided.draggableProps}>
                <Header
                  isDragging={snapshot.isDragging}
                  style={{width: '310px', backgroundColor: `${selectedColor || bgColor}`}}
                  className={`${index === 1 && 'head_col_tab'}`}
                >
                  <Title
                    className='text-white text-truncate mt-1'
                    isDragging={snapshot.isDragging}
                    {...provided.dragHandleProps}
                  >
                    <EditableTitle
                      columnId={columnId}
                      role={role}
                      title={newTitle || title}
                      newTitle={newTitle}
                      isEditable={isEditable}
                      columnTitle={columnTitle}
                      setColumnTitle={setColumnTitle}
                      isPageArchiveBoard={isPageArchiveBoard}
                      setIsEditable={setIsEditable}
                      setColumnIdState={setColumnIdState}
                      updateColumnTitleHandler={updateColumnTitleHandler}
                    />
                  </Title>
                  {/* Guest mode working on hide card controllers  */}
                  {!isEditable && !isPageArchiveBoard ? (
                    <ColumnHeaderButtons
                      role={role}
                      setCardModalShow={setAddModalShow}
                      setCardPositionStatus={setCardPositionStatus}
                      setShowColor={setShowColor}
                      setColumnIdState={setColumnIdState}
                      columnId={columnId}
                      handleRuleModalShow={handleRuleModalShow}
                      index={index}
                      setBackgroundColorState={setBackgroundColorState}
                      defaultColor={bgColor}
                    />
                  ) : null}
                </Header>

                    {boardCardFetchLoading ? (
                      <div className='custom-class1'><ReactSkeleton count={3} height={skeletonHeight} /></div>
                    ) : (
                      <>
                        <CardList
                          role={role}
                          className={`custom-class1  ${theme === 'dark' ? 'layout__theme__div' : ''}`}
                          style={cardListStyle}
                          listId={columnId}
                          listType='QUOTE'
                          checked={checked}
                          quotes={quotes}
                          internalScroll={isScrollable}
                          isCombineEnabled={Boolean(isCombineEnabled)}
                          useClone={Boolean(useClone)}
                          fetchSortedCardHandler={fetchSortedCardHandler}
                          // fetchCardHandler={fetchCardHandler}
                          cards={cards}
                          allCards={allCards}
                          handleCloneCard={handleCloneCard}
                          setCards={setCards}
                        />
                        <AddCardFromDownBtn
                          role={role}
                          index={index}
                          columnId={columnId}
                          setCardModalShow={setAddModalShow}
                          setCardPositionStatus={setCardPositionStatus}
                        />
                      </>
                    )}
                  
              </Container>
            )}
          </Draggable>
        </div>
      ) : (
        <div>
          {/* If role is not admin restrict them to access of column update */}
          <Container>
            <Header style={{width: '310px', backgroundColor: `${selectedColor || bgColor}`}}>
              <Title className='text-white text-truncate mt-1'>
                <EditableTitle
                  columnId={columnId}
                  role={role}
                  title={title}
                  newTitle={newTitle}
                  isEditable={isEditable}
                  columnTitle={columnTitle}
                  setColumnTitle={setColumnTitle}
                  isPageArchiveBoard={isPageArchiveBoard}
                  setIsEditable={setIsEditable}
                  setColumnIdState={setColumnIdState}
                  updateColumnTitleHandler={updateColumnTitleHandler}
                />
              </Title>
              {/* Guest mode working on hide card controllers  */}
              {!isEditable &&
              !isPageArchiveBoard &&
              checkRole?.isGuest !== true &&
              role !== 'Guest' ? (
                <ColumnHeaderButtons
                  columnId={columnId}
                  role={role}
                  setCardModalShow={setAddModalShow}
                  setCardPositionStatus={setCardPositionStatus}
                  setShowColor={setShowColor}
                  setColumnIdState={setColumnIdState}
                  handleRuleModalShow={handleRuleModalShow}
                  setBackgroundColorState={setBackgroundColorState}
                  defaultColor={bgColor}
                />
              ) : null}
            </Header>
        
              {boardCardFetchLoading ? (
                <div className='custom-class1'><ReactSkeleton count={3} height={skeletonHeight} /></div>
              ) : (
                <>
              <CardList
                role={role}
                id={columnId}
                className={`custom-class2 ${theme === 'dark' ? 'layout__theme__div' : ''}`}
                style={cardListStyle}
                listId={columnId}
                listType='QUOTE'
                checked={checked}
                quotes={quotes}
                internalScroll={isScrollable}
                isCombineEnabled={Boolean(isCombineEnabled)}
                cards={cards}
                allCards={allCards}
                setCards={setCards}
                fetchSortedCardHandler={fetchSortedCardHandler}
                // fetchCardHandler={fetchCardHandler}
                handleCloneCard={handleCloneCard}
              />
              </>
            )}

            {!isPageArchiveBoard && (
              <AddCardFromDownBtn
                role={role}
                columnId={columnId}
                setCardModalShow={setAddModalShow}
                setCardPositionStatus={setCardPositionStatus}
              />
            )}
          </Container>
        </div>
      )}

      {/* ----------------Add a Card------------------ */}
      {!isPageArchiveBoard && addCardModalShow && (
        <AddNewCard
          cardPositionStatus={cardPositionStatus}
          columnIdProp={columnId}
          columnTitle={title}
          cardTitle='Add New Task'
          cardModalShow={addCardModalShow}
          setCardModalShow={setAddModalShow}
          setCards={setCards}
          role={role}
          allCards={allCards}
          columnResponse={columnResponse}
        />
      )}

      {/* ------------------Delete Column Modal-------------------- */}

      <DeleteColumnModal
        showDeleteModal={deleteModalShow}
        handleCloseDeleteModal={handleClose}
        title={newTitle && title ? newTitle.trim() || title.trim() : newTitle || title}
        deleteModalMessage={deleteModalMessage}
        onDeleteHandler={deleteColumnHandler}
      />
      <ColumnRulesModal columnRuleModalShow={columnRuleModalShow} handleRuleModalClose={handleRuleModalClose}
      role={role} options={optionsOfAssignee} selected={selectAutoAssignee} setSelected={setSelectAutoAssignee} handleShow={handleShow} title={newTitle && title ? newTitle.trim() || title.trim() : newTitle || title} columnResponse={columnResponse} columnId={columnId} userId={userId} boardId={params.boardId} setColumnsResponse={setColumnsResponse} />

      {/* ------------------Change Column Color Modal-------------------- */}
      <UpdateColumnColorModal
        showColorModal={showColor}
        handleCloseColorModal={handleClose}
        backgroundColorState={backgroundColorState}
        setBackgroundColorState={setBackgroundColorState}
        updateColumnColor={updateColumnColor}
        columnsBg={columnsBg}
        colorLoading={colorLoading}
      />
    </>
  )
}

export default Column
