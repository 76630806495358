import React, { useEffect, useState } from 'react'
import EditPageForm from '../Forms/EditPageForm'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { longWordSlicing } from '../../helper-functions/CheckString'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import useGetTags from '../../hooks/useGetTags'
import { useBoardPersons, useFileUploader, useGetMember } from '../../hooks'
import useSendAlert from '../../hooks/useSendAlert'
import { clonePage, deletePageFileAttachment, updatePageById } from '../../services/pages'
import ReactS3Client from 'react-aws-s3-typescript'
import { awsFileUploadConfig } from '../../config/awsFileUpload'
import ClonePageModal from './ClonePageModal'
import { getBoardPersonById } from '../../services/boards'
import UserEditModal from '../../modules/user-management/users-list/user-edit-modal/UserEditModal'
import '../../../app/styles/tableOfContents.css'
import { useDispatch } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { addBoardTags, removeBoardTags } from '../../store/slice/board-tags-slice'
import { addPageInLoggedInUser, editPageInLoggedInUser } from '../../store/slice/page-slice'
import DropdownClone from '../CloneCardDropdown'


const pageUpdateSchema = Yup.object().shape({
    title: Yup.string()
        .min(3, 'Minimum 3 Characters')
        .max(300, 'Maximum 300 Characters')
        .required('Title is Required')
        .trim(),
    webAppName: Yup.string()
        .min(3, 'Minimum 3 Characters')
        .max(300, 'Maximum 300 Characters').nullable()
        .trim(),
})

const EditPageModal: React.FC<any> = ({ boardName,theme, pageModalShow, setPageModalShow, page, setPages, pages, sendTocHeading, setSendTocHeading }) => {
    const params: any = useParams()
    const [openCloneModal, setOpenCloneModal] = useState<any>(false);
    const [loadingClone, setLoadingClone] = useState<any>(false);
    const [tocHeading, setTocHeading] = useState<any>(sendTocHeading || null);

    const { allBoards } = useSelector((state: any) => state.allBoardsReducer)
    const [boardId, setBoardId] = useState(params.boardId || page.boardId)
    const [disableBtn, setDisableBtn] = useState(false)
    const dispatch: Dispatch<any> = useDispatch()
    const [imageErrorMsg, setImageErrorMsg] = useState<string>('')

    const s3 = new ReactS3Client({
        ...awsFileUploadConfig,
        dirName: `notes-attachments/${process.env.REACT_APP_ENVIRONMENT}`,
    })

    const transformedTags = (page?.tags || []).map((tag: any) => ({
    value: tag.id,
    label: tag.name
    }));
    const selectedAssignee = page?.board_people.map((people: any) => ({
        value: people.id,
        label: people.user.fullName
    }));


    const [selectedTags, setSelectedTags] = useState(transformedTags)
    const [tagsToUpdate, setTagsToUpdate] = useState<any>({
        addedTags: [],
        removedTags: [],
    });
    const [assigneeToUpdate, setAssigneeToUpdate] = useState<any>({
        addedAssignee: [],
        removedAssignee: [],
    });


    // const { getPageHandler, page: getPage, pageloading } = useGetPages(boardId)

    const [editorTags, setEditorTags] = useState<any>(null)

    const [editorDescriptionState, setEditorDescriptionState] = useState<any>(null)
    const [socialMediaContent, setsocialMediaContent] = useState<any>(null)
    const [otherLinksContent, setOtherLinksContent] = useState<any>(null)
    const [optionsForSendAlertEdit, setOptionsForSendAlertEdit] = useState<any>(null)

    const [selectedOption, setSelectedOption] = useState<any>(page?.status === 'active' ? { value: 'active', label: 'Active' } : { value: 'hidden', label: 'Hidden' })

    const { tags, loading: tagsLoading } = useGetTags() 
    const {
        optionsOfAssignee,
        isUserAssignLoading,
        selectAutoAssigneeForNotes,
        setSelectAutoAssigneeForNotes,
        optionsForSendAlert,
        boardPersonsForSendAlert,
        getBoardPersonHandler,
    } = useBoardPersons(page)
    const {
        sendAlertTo,
        setSendAlertTo,
        sendAlertMsg,
        setSendAlertMsg,
        sendAlertToBoardPerson
    } = useSendAlert(
        boardPersonsForSendAlert,
        page,
        boardId,
        undefined,
        editorDescriptionState
    )
    const { boardPerson } = useGetMember(boardId)
    const [openInviteModal, setOpenInviteModal] = useState(false)

    const { fileErrorMsg, selectedFile, setSelectedFile, selectFileHandler, setFileErrorMsg } =
        useFileUploader()

    const deleteFileHandler = async (name: string, key: string, value: string) => {
        let files: any
        if (key) {
            files = selectedFile.filter((item: any) => item.key !== key)
        } else {
            files = selectedFile.filter((item: any) => item.name !== name)
        }
        try {
            const fileNameForDelete = value || name
            await s3.deleteFile(fileNameForDelete)
            try {
                key && await deletePageFileAttachment(page?.id, key)
                setSelectedFile(files)
            } catch (error) {
                return error
            }
        } catch (error) {
            return error
        }
    }

    const getAddedAndRemovedTags = () => {
        const addedTags = selectedTags?.filter((tag: any) => !transformedTags?.some((t: any) => t.value === tag.value));
        const removedTags = transformedTags?.filter((tag: any) => !selectedTags?.some((t: any) => t.value === tag.value));
        setTagsToUpdate({ addedTags, removedTags });
    };

    const getAddedAndRemovedAssignee = () => {
        const addedAssignee = selectAutoAssigneeForNotes?.filter((assignee: any) => !selectedAssignee.some((a: any) => a.value === assignee.value));
        const removedAssignee = selectedAssignee?.filter((assignee: any) => !selectAutoAssigneeForNotes.some((a: any) => a.value === assignee.value));

        setAssigneeToUpdate({ addedAssignee, removedAssignee });
    };

    useEffect(() => {

        const urlLinks: any = []
        if (page?.file_attachments) {
            for (const file of page?.file_attachments) {
                for (const [key, value] of Object.entries(file)) {
                    const getFileName = (value as string)
                        .toLowerCase()
                        ?.split(`${process.env.REACT_APP_ENVIRONMENT}/`)[1]
                    urlLinks.push({ key, value: value, showName: getFileName })
                }
            }
            setSelectedFile(urlLinks)
        }
        return () => {
            setSelectedFile([]);
        };
        //eslint-disable-next-line
    }, [page])
    useEffect(() => {
        getAddedAndRemovedTags()
    }, [selectedTags])

    useEffect(() => {
        getAddedAndRemovedAssignee()
    }, [selectAutoAssigneeForNotes])

    useEffect(() => {
        if (page?.board_people?.length) {
            const filteredOptions = optionsForSendAlert?.filter((option: any) =>
                page.board_people.filter((person: any) => person.user.fullName === option.label)
            );
            setOptionsForSendAlertEdit(filteredOptions);
        }
    }, [optionsForSendAlert, page]);

    useEffect(() => {
        if (boardId) {
            getBoardPersonHandler(boardId)
        }
        //eslint-disable-next-line
    }, [boardId])
    // useEffect(() => {
    //     getPageHandler(page?.id)
    //     //eslint-disable-next-line

    // }, [page?.id])
    useEffect(() => {
        setEditorDescriptionState(page?.description)
        setsocialMediaContent(page?.socialLinks)
        setOtherLinksContent(page?.otherLinks)
        //eslint-disable-next-line

    }, [page])

    const clonePageHandler = async (page: any,title:string) => {
        setLoadingClone(true)
        try {
            const pageWithKeyCount = pages?.filter((page: any) => page?.key)?.length || 0;
            const payload: any = {
                pageId: page?.id,
                title: title,
                website_name: page.website_name,
                boardId: page?.boardId,
                status: page?.status,
                description: page?.description,
                assignee_board_person_ids: page?.board_people.map((person: any) => person.id),
                boardName,
                pageNumber: pageWithKeyCount+1
            };
            const res = await clonePage(payload)
            const clonedPage: any = res.data.data.page
            if(pages){
                setPages((prevPages: any[]) => [clonedPage, ...prevPages])
            }
            dispatch(addPageInLoggedInUser(clonedPage))            

        } catch (error) {
            // console.log(error )

        } finally {
            setLoadingClone(false)
            setOpenCloneModal(false)
            setPageModalShow(false)
        }
    }
    const handleSubmission = async () => {
        const urls = []
        for (let index = 0; index < selectedFile.length; index++) {
            const filename = selectedFile[index].name
            if (filename) {
                const filenameUpdated = filename.substring(0, filename.lastIndexOf('.'))
                try {
                    const uploadFile: any = await s3.uploadFile(selectedFile[index], filenameUpdated)
                    urls.push(uploadFile.location)
                } catch (error) {
                    return error
                }
            }
        }

        return urls
    }
    const initialValues = {
        title: page.title,
        webAppName: page.website_name,
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: pageUpdateSchema,
        onSubmit: async (values: any) => {
            getAddedAndRemovedAssignee()
            setDisableBtn(true)

            const formData = new FormData();
            const fileLinks: any = await handleSubmission()
            const { title, webAppName } = values
            formData.append('title', title);

            formData.append('boardName', boardName);
            if(!page.key || page.pageNumber === null){

                const pageWithKeyCount = pages?.filter((page: any) => page?.key)?.length || 0;
                formData.append('pageNumber', `${pageWithKeyCount + 1}`);
            }else{
                formData.append('pageNumber', page?.pageNumber);
            }

            webAppName && formData.append('website_name', webAppName);
            editorDescriptionState && formData.append('description', editorDescriptionState);

            otherLinksContent && formData.append('otherLinks', otherLinksContent);
            socialMediaContent && formData.append('socialLinks', socialMediaContent);

            formData.append('boardId', boardId);
            formData.append('status', selectedOption.value);

            tagsToUpdate.addedTags.forEach((tag: any) => formData.append('new_tagIds[]', tag.value));
            tagsToUpdate.removedTags.forEach((tag: any) => formData.append('remove_tagIds[]', tag.value));

            assigneeToUpdate.addedAssignee.forEach((assignee: any) => formData.append('new_assignee_board_person_ids[]', assignee.value));
            assigneeToUpdate.removedAssignee.forEach((assignee: any) => formData.append('remove_assignee_board_person_ids[]', assignee.value));

            fileLinks.forEach((link: string) => formData.append('new_file_attachments[]', link));


            try {
                const res = await updatePageById(page?.id, formData)
                const updatedPage: any = res.data.data.page
                dispatch(editPageInLoggedInUser(updatedPage))
                const filteredPages = pages && pages?.filter((p: any) => p.id !== page.id)
                if(pages){
                    setPages([updatedPage, ...filteredPages])
                }

                const addedTags = tagsToUpdate.addedTags.map((tag: any) => ({ label: tag.label, value: tag.value }));
                addedTags.length && dispatch(addBoardTags(addedTags));
            
                const removedTagIds = tagsToUpdate.removedTags.map((tag: any) => tag.value);
                removedTagIds.length && dispatch(removeBoardTags(removedTagIds));

                if (sendAlertTo.length > 0 && editorDescriptionState !== null)
                    sendAlertToBoardPerson(updatedPage, sendAlertTo, 'page-description')
                setPageModalShow(false)
            } catch (error:any) {
                // console.log(error)
                if(error.response.status === 400 && error.response.data.error.includes('Field value too long')){
                    setImageErrorMsg('Description field value exceeds the 50MB limit.')
                  }
            }finally{
                setDisableBtn(false)
            }
        }

    })
    const getMembersHandler = async () => {
        try {
            const res = await getBoardPersonById(params.boardId || page.boardId)
            const resData = res.data.data
            const resObject: any = []
            for (let index = 0; index < resData.length; index++) {
                if (resData[index].isDeleted === false) {
                    resObject.push({
                        id: resData[index].id,
                        name: resData[index].user.fullName,
                        email: resData[index].user.email,
                        role: resData[index].role.name,
                        roleId: resData[index].roleId,
                        isOwner: resData[index].isOwner,
                    })
                }
            }
        } catch (error) {
            return error
        }
    }
    const handleCloneDropdown=()=>{
        setOpenCloneModal(true)
      }
    return (
        <>
            <Modal
                contentClassName={theme === 'dark' ? 'theme__div modal__bg' : ''}
                show={pageModalShow}
                size='xl'
                aria-labelledby='contained-modal-title-vcenter'
                animation={false}
                onHide={() => {
                    setPageModalShow(false)
                    setSelectedFile([])
                    setTocHeading(null)
                    sendTocHeading && setSendTocHeading(null)
                }}
                scrollable
            >
                <div className='row d-flex align-items-center justify-content-between border-bottom fs-2 fw-bold p-8'>
                    <div className='text-primary col-6'>

                        <span className={`responsive-font ${theme === 'dark' ? 'white__text' : ''} `} style={{ marginRight: 20 }}>

                            {longWordSlicing(page?.title?.trim())}
                        </span>

                    </div>
                    <div className='col-4 text-end'>
                        <Button
                            disabled={disableBtn || boardPerson?.role === 'Guest'}
                            className={`${disableBtn && 'btn-custom-secondary '} btn-sm w-75`}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                        >
                            {disableBtn ? <Spinner animation='border' variant='light' /> : 'Save'}
                        </Button>
                    </div>
                    {boardPerson?.role === 'Guest' ? null :
                        (<div
                            className='options-button col-1 text-end m-auto'
                            onClick={(e) => e.stopPropagation()}
                        >
                        <DropdownClone handleShowModal={handleCloneDropdown} cardData={page} category={'page'} boardName={boardName} />

                        </div>)}
                    <div className='col-1 text-end'>
                        <span
                            className='text-center p-2 cursor-pointer d-inline-block pb-0'
                            onClick={() => setPageModalShow(false)}
                        >
                            X
                        </span>
                    </div>
                </div>
                <Modal.Body>
                    <EditPageForm
                        theme={theme}
                        formik={formik}
                        editor={editorDescriptionState}
                        setEditor={setEditorDescriptionState}
                        boardId={boardId}
                        setBoardId={setBoardId}
                        allBoards={allBoards}
                        tags={tags}
                        loading={tagsLoading}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        boardPerson={boardPerson}
                        optionsForSendAlert={optionsForSendAlertEdit}
                        setSendAlertTo={setSendAlertTo}
                        sendAlertTo={sendAlertTo}
                        isUserAssignLoading={isUserAssignLoading}
                        setSelected={setSelectAutoAssigneeForNotes}
                        selected={selectAutoAssigneeForNotes}
                        options={optionsOfAssignee}
                        setSendAlertMsg={setSendAlertMsg}
                        sendAlertMsg={sendAlertMsg}
                        selectedOption={selectedOption} setSelectedOption={setSelectedOption}
                        loadingDesc={false}
                        transformedTags={transformedTags}
                        deleteFileHandler={deleteFileHandler}
                        selectedFile={selectedFile}
                        changeHandler={selectFileHandler}
                        fileErrorMsg={fileErrorMsg}
                        setFileErrorMsg={setFileErrorMsg}
                        setOpenInviteModal={setOpenInviteModal}
                        socialMediaContent={socialMediaContent}
                        setsocialMediaContent={setsocialMediaContent}
                        otherLinksContent={otherLinksContent}
                        setOtherLinksContent={setOtherLinksContent}
                        editorTags={editorTags} setEditorTags={setEditorTags}
                        tocHeading={tocHeading} setTocHeading={setTocHeading}
                        updatedDate={page?.updatedAt}
                        setImageErrorMsg={setImageErrorMsg}
                        imageErrorMsg={imageErrorMsg}
                         />
                </Modal.Body>
            </Modal>
            {openInviteModal && (
                <UserEditModal membersData={optionsOfAssignee} getMembersHandler={getMembersHandler} sendWebSocket={undefined} isPage={true} setShow={setOpenInviteModal} show={openInviteModal} />
            )}
            {openCloneModal && (
                <ClonePageModal openCloneModal={openCloneModal} setOpenCloneModal={setOpenCloneModal} page={page} loading={loadingClone} clonePageHandler={clonePageHandler} />
            )}
        </>
    )
}

export default EditPageModal