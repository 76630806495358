import React, {useEffect, useState} from 'react'
import { cloneCard,getLastUpdatedCard} from '../../services/cards'
import EditNewCard from '../UpdateCard/EditBoardCard'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {useAuth} from '../../modules/auth'
import {useDispatch, useSelector} from 'react-redux'

import {Dispatch} from '@reduxjs/toolkit'
import {setIsSubscription} from '../../store/slice/subscription-slice'
import {useNavigate} from 'react-router-dom'
import {useTheme} from '../../context/ThemeContext'
import { setIsCardUpdated } from '../../store/slice/last-update-slice'
import { v4 as uuidv4 } from 'uuid'
import { CopyObjectCommand } from '@aws-sdk/client-s3'
import { getFileExtension } from '../../helper-functions/GetImageExtension'
import useGetCards from '../../hooks/useGetCards'
import { s3Client } from '../../config/awsFileUpload'
import { setIsRender } from '../../store/slice/isRender-slice'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { addAllCurrentUserCards } from '../../store/slice/card-slice'
interface PropsTypes {
  setCheckedLastUpdate?: any
}

const LastUpdate: React.FC<PropsTypes> = ({setCheckedLastUpdate}) => {
  const {theme} = useTheme()
  const [loading, setLoading] = useState(true)
  const [cloneLoading, setCloneLoading] = useState(false)
  const [toClone, setToClone] = useState({})

  const [cardModalShow, setCardModalShow] = useState(false)
    const [cloneModalShow, setCloneModalShow] = useState(false)
  const handleCloneModalClose = () => setCloneModalShow(false)
  const handleCloneModalShow = () => setCloneModalShow(true)
  const [card, setCard] = useState<any>({})
  const isLastUpdated = useSelector((state: any) => state.lastUpdateReducer)
  const { isCardUpdated } = isLastUpdated.isCardUpdated
  const { fetchCardHandler } = useGetCards()

  // const allCardsRedux = useSelector((state: any) => state.cardReducer)
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const {id} = currentUser?.data.user
  const dispatch: Dispatch<any> = useDispatch()
  const isRenderStore = useSelector((state: any) => state.isRenderReducer)
  const { isRender } = isRenderStore.isRender
  const cloneCardHandler = async (card: any, selectedKeys: string[],cardDescription:string,finalTitle:string) => {
    try {
      setCloneLoading(true)
      const cardsInBoard: any = await fetchCardHandler(card.boardId)
      let payload: any = {
        cardid: card.id,
        created_by: card.created_by,
        last_modified_by: card.last_modified_by,
        board_name: card.board_name.trim(),
        number: (cardsInBoard.length + 1).toString(),
        position: cardsInBoard.length + 1,
        boardId: card.boardId,
        board_column_id: card.board_column_id,
        reporter: card.reporter,
        cardType: card.cardType,
        description: cardDescription && cardDescription,
        card_name: finalTitle,
      }

      for (const key of selectedKeys) {
        if (key.toString() === "card_name") {
          continue;
        }
        if (Object.prototype.hasOwnProperty.call(card, key)) {
          if (key.toString() === 'file_attachments') {
            if (card.file_attachments && card.file_attachments.length > 0) {
              for (const file of card.file_attachments) {
                const filename = uuidv4()
                const fileUrl = (Object.values(file) as string[])[0]
                const getFileName = fileUrl.split(`${process.env.REACT_APP_ENVIRONMENT}/`)[1]
                const copySource = `${process.env.REACT_APP_FILE_UPLOAD_BUCKET}/cards-attachments/${process.env.REACT_APP_ENVIRONMENT}/${getFileName}`
                const destinationKey = `cards-attachments/${process.env.REACT_APP_ENVIRONMENT
                  }/${filename}.${getFileExtension(fileUrl)}`

                const command = new CopyObjectCommand({
                  CopySource: copySource,
                  Bucket: process.env.REACT_APP_FILE_UPLOAD_BUCKET,
                  Key: destinationKey,
                })

                if (payload['file_attachments'] === undefined) {
                  payload['file_attachments'] = [
                    `https://${process.env.REACT_APP_FILE_UPLOAD_BUCKET}.s3.amazonaws.com/${destinationKey}`,
                  ]
                } else {
                  payload['file_attachments'] = payload.file_attachments.map((item: any) => item)
                  payload['file_attachments'].push(
                    `https://${process.env.REACT_APP_FILE_UPLOAD_BUCKET}.s3.amazonaws.com/${destinationKey}`
                  )
                }

                try {
                  await s3Client.send(command)
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error('Error copying object:', error)
                }
              }
            }
          } else if (key.toString() === 'assignees') {
            if (card.assignees && card.assignees.length > 0) {
              const allAssignees = card.assigneeBoardPersonIds.map((assignee: any) => {
                return {
                  board_person_id: assignee.boardPersonId,
                }
              })
              payload[key] = allAssignees
            }
          } else if (key.toString() === 'due_date') {
            payload[key] = card[key]
            payload['due_time'] = card['due_time']
          } else if(key.toString() === "tags"){
            payload["tagIds"] = card.tags.length > 0 ? card.tags.map((tag: any) => { return tag.id} ) : []
          }
          else {
            payload[key] = card[key];
          }
        }
      }

      if (!Object.prototype.hasOwnProperty.call(payload, 'status')) {
        payload = { ...payload, status: 'todo'}
      }

      const res:any=await cloneCard(payload, id)
      dispatch(setIsCardUpdated({ isCardUpdated: !isCardUpdated }))
      dispatch(setIsRender({ isRender: !isRender }))
      dispatch(addAllCurrentUserCards(res.data.data));


    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setCloneLoading(false)
      handleCloneModalClose()
      setCardModalShow(false)
    }
  }
  const lastUpdateHandler = async () => {
    setLoading(true)
    try {
        const res = await getLastUpdatedCard()
        const getCard = res.data.data.lastUpdatedCard.card
        setCard(getCard)
        setCheckedLastUpdate(getCard)

    } catch (error: any) {
      const status = await error?.response?.status
      // if (status === "Please First Buy a Subscription" || msg === "Your Subscription has been Expired") {
      if (status === 403) {
        dispatch(setIsSubscription({isSubscription: 'expired'}))
        setTimeout(() => {
          navigate('/billing/billing-details')
        }, 1000)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleClick = () => {
    setCardModalShow(true)
  }
  useEffect(() => {
    lastUpdateHandler()
    //eslint-disable-next-line
  }, [isLastUpdated])

  return (
    <>
      <h3 className='fw-bolder my-2 text-end text-truncate d-inline-block'>
        {loading ? (
          <div className='w-100'>
           
            <Skeleton count={1} height={17} width={200}  />
          </div>
        ) : (
          <>
            {card && (
              <>
                <span className={`d-inline-block ${theme === 'dark' ? 'white__text' : ''}`}>
                  <span className='me-2'><img src={theme === 'dark' ? toAbsoluteUrl('/media/LastUpdate_white.svg'):toAbsoluteUrl('/media/LastUpdate_black.svg')} /></span>
                  Last Updated:{' '}
                </span>
                <span
                  className='m-0 me-4 ms-1 text-primary'
                  style={{cursor: 'pointer'}}
                  onClick={handleClick}
                >
                  {card?.key?.trim()}
                </span>
                {cardModalShow && (
                  <EditNewCard
                    cardTitle='Edit Card'
                    isBoard={false}
                    cardDetails={card}
                    cardModalShow={cardModalShow}
                    setCardModalShow={setCardModalShow}
                    cloneModalShow={cloneModalShow}
                    handleCloneModalClose={handleCloneModalClose}
                    handleCloneModalShow={handleCloneModalShow}
                    setToClone={setToClone}
                    toClone={toClone}
                    cloneCardHandler={cloneCardHandler}
                    loading={cloneLoading}
                    isLastUpdate={true}
                  />
                )}
              </>
            )}
          </>
        )}
      </h3>
    </>
  )
}

export default LastUpdate
