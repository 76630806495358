import React from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import { useTheme } from '../../../../app/context/ThemeContext'

const HeaderUserMenu = (): React.JSX.Element => {
  const {theme} = useTheme()
  const {currentUser, logout} = useAuth()
  const {hasOrganization} = currentUser
  const {
    fullName,
    email,
    image,
    isVerified,
    isInvitedByOrganization,
    role: checkRole,
  } = currentUser?.data.user

  return (
    <div
      className={`${theme === 'dark' ? 'theme__div' : ''} menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px`}
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className={`symbol symbol-70px me-5 ${image ? 'dropdown_img' : ''}`}>
            <img
              alt='profile'
              src={image ? image : '/media/person-fill.svg'}
              style={{objectFit: 'contain'}}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center '>
              <span
                className={`fs-5 ${theme === 'dark' ? 'white__text' : ''}`}
                style={{
                  maxWidth: '210px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {fullName}
              </span>
            </div>
            <a className='fw-bold text-muted fs-7'>{email}</a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className={`menu-link px-5 ${theme === 'dark' ? 'white__text' : ''}`}>
          My Profile
        </Link>
      </div>

      {isVerified &&
        !hasOrganization &&
        !isInvitedByOrganization &&
        checkRole?.isGuest === false && (
          <div className='menu-item px-5'>
            <Link
              to={'/billing/billing-details'}
              className={`menu-link px-5 ${theme === 'dark' ? 'white__text' : ''}`}
            >
              Renewal & Billing
            </Link>
          </div>
        )}
      {checkRole?.isGuest === true && (
        <div className='menu-item px-5'>
          <Link
            to={'/account/upgrade'}
            className={`menu-link px-5 ${theme === 'dark' ? 'white__text' : ''}`}
          >
            Upgrade Account
          </Link>
        </div>
      )}

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link
          to='/'
          onClick={() => {
            logout()
          }}
          className={`menu-link px-5 ${theme === 'dark' ? 'white__text' : ''}`}
        >
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
