import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTheme } from '../../context/ThemeContext'
import useVersioning from '../../hooks/useVersioning'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const VersionUpdateModal: React.FC = () => {
  const { theme } = useTheme()
  const { reload, versionContent, setReload } = useVersioning()
  if (!versionContent) {
    return null;
  }
  return (
    <Modal
      contentClassName={theme === 'dark' ? 'layout__theme__div' : ''}
      show={reload}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      scrollable
    >
      <Modal.Header>
        <Modal.Title>
          <h1 className={theme === 'dark' ? 'white__text' : ''}>New Update!</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-start align-items-center flex-column'>
          <div className='fs-2'>New updates of the app is available. Please refresh the page.</div>
          <div dangerouslySetInnerHTML={{ __html: versionContent }} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='align-self-end w-25'>
          <Button variant='secondary' className='btn-sm' onClick={() => {
            setReload(false)
            window.location.reload()
          }}>
            <img className="w-75" src={toAbsoluteUrl('/media/logos/b4igo-go.png')} />
          </Button>
        </div>
      </Modal.Footer>
    </Modal >
  )
}

export default VersionUpdateModal
